import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/others/Header';
import Footer from './components/others/Footer';
import WhatsAppButton from './components/others/WhatsAppButton';
import ScrollToTop from './components/others/ScrollToTop';
import { HelmetProvider, Helmet } from 'react-helmet-async'; // Importă HelmetProvider și Helmet
import './i18n';
import { createRoot } from 'react-dom/client'; // Importă createRoot din react-dom/client

// Importă componentele folosind Lazy Loading
const Homepage = React.lazy(() => import('./components/home/Homepage'));
const LocationPage = React.lazy(() => import('./components/location/LocationPage'));
const TeamPage = React.lazy(() => import('./components/team/TeamPage'));
const ContactPage = React.lazy(() => import('./components/contact/ContactPage'));

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <Helmet>
            <title>Cluj Tattoo Club - Premier Tattoo Studio</title>
            <meta
              name="description"
              content="Cluj Tattoo Club - Premier tattoo studio in Cluj-Napoca, offering high-quality and unique tattoo designs."
            />
            <meta property="og:title" content="Cluj Tattoo Club - Premier Tattoo Studio" />
            <meta
              property="og:description"
              content="Cluj Tattoo Club - Premier tattoo studio in Cluj-Napoca, offering high-quality and unique tattoo designs."
            />
            <meta property="og:image" content="/path/to/your/image.jpg" />
            <meta property="og:url" content="https://clujtattooclub.com" />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />

            {/* Meta tag-uri Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Cluj Tattoo Club - Premier Tattoo Studio" />
            <meta name="twitter:description" content="Cluj Tattoo Club - Premier tattoo studio in Cluj-Napoca, offering high-quality and unique tattoo designs." />
            <meta name="twitter:image" content="/path/to/your/image.jpg" />
            <meta name="twitter:site" content="@ClujTattooClub" />

            {/* Canonical URL pentru evitarea conținutului duplicat */}
            <link rel="canonical" href="https://clujtattooclub.com" />

            {/* Date structurate (Schema.org) pentru a îmbunătăți SEO */}
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "TattooParlor",
                "name": "Cluj Tattoo Club",
                "image": "https://clujtattooclub.com/images/tattoo-studio.jpg",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "Strada Ion Ratiu, Nr. 7",
                  "addressLocality": "Cluj-Napoca",
                  "addressRegion": "CJ",
                  "postalCode": "400000",
                  "addressCountry": "RO"
                },
                "telephone": "+40738965804",
                "priceRange": "$$",
                "description": "Premier tattoo studio in Cluj-Napoca, offering custom tattoo designs and professional artists.",
                "url": "https://clujtattooclub.com"
              })}
            </script>
          </Helmet>

          <Header />
          {/* Suspense afișează fallback-ul până când componentele sunt încărcate */}
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/location" element={<LocationPage />} />
              <Route path="/team" element={<TeamPage />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
          </Suspense>
          <Footer />
          <ScrollToTop />
          <WhatsAppButton />
        </div>
      </Router>
    </HelmetProvider>
  );
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

export default App;
