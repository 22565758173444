// components/Footer.js
import React from 'react';
import './css/Footer.css';

function Footer() {
  const currentYear = new Date().getFullYear(); // Obține anul curent din JavaScript

  return (
    <footer className="footer">
      <p>© {currentYear} Cluj Tattoo Club. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
