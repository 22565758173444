import React from 'react';
import './css/WhatsAppButton.css'; // Importăm fișierul CSS

function WhatsAppButton() {
  return (
    <a
      href="https://wa.me/40738965804"
      className="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="Chat on WhatsApp" />

    </a>
  );
}

export default WhatsAppButton;
