// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // Importă detectorul de limbă
import enTranslation from './locales/en/translation.json';
import roTranslation from './locales/ro/translation.json';

i18n
  .use(LanguageDetector) // Utilizează detectorul de limbă
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      ro: {
        translation: roTranslation,
      },
    },
    fallbackLng: 'ro', // Limba implicită dacă nu se detectează alta
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'navigator'], // Prioritizează limba salvată în localStorage, apoi limba browserului
      caches: ['localStorage'], // Salvează limba selectată în localStorage
    },
  });

export default i18n;
