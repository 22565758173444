import React, { useState, useEffect } from 'react';
import './css/Header.css';
import { FaBars, FaTimes } from 'react-icons/fa'; // Importăm iconițele pentru burger și X
import { useTranslation } from 'react-i18next';

function Header() {
  const [scroll, setScroll] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // State pentru curtain menu
  const { i18n } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Schimbă starea curtain menu-ului
  };

  // Funcție pentru schimbarea limbii și salvarea acesteia în localStorage
  const changeLanguage = () => {
    const newLanguage = i18n.language === 'ro' ? 'en' : 'ro';
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('language', newLanguage); // Salvează limba selectată în localStorage
  };

  return (
    <header className={`hdr-header ${scroll ? 'hdr-header-scroll' : ''}`}>
      <img src="/images/logo.png" alt="Logo" className="hdr-logo-header" />
      <div className="hdr-navigation">
        {/* Buton pentru schimbarea limbii */}
        <button className="hdr-language-button" onClick={changeLanguage}>
          <img
            src={i18n.language === 'ro' ? '/images/flag_ro.png' : '/images/flag_uk.png'}
            alt="Language Flag"
            className="hdr-language-flag"
          />
        </button>
        <nav className="hdr-nav">
          <a href="/" className="hdr-nav-link">{i18n.t('home')}</a>
          <a href="/location" className="hdr-nav-link">{i18n.t('ourLocation')}</a>
          <a href="/team" className="hdr-nav-link">{i18n.t('team')}</a>
          <a href="/contact" className="hdr-nav-link">{i18n.t('contactHeader')}</a>
        </nav>
        <FaBars className="hdr-burger-icon" onClick={toggleMenu} /> {/* Icon de burger */}
      </div>

      {/* Curtain Menu */}
      <div className={`hdr-curtain-menu ${menuOpen ? 'active' : ''}`}>
        <button className="hdr-close-icon" onClick={toggleMenu}><FaTimes /></button>
        <img src="/images/logo.png" alt="Logo" className="hdr-curtain-logo" />
        <nav className="hdr-curtain-nav">
          <a href="/" className="hdr-curtain-nav-link" onClick={toggleMenu}>
            {i18n.t('home')}
          </a>
          <a href="/location" className="hdr-curtain-nav-link" onClick={toggleMenu}>
            {i18n.t('ourLocation')}
          </a>
          <a href="/team" className="hdr-curtain-nav-link" onClick={toggleMenu}>
            {i18n.t('team')}
          </a>
          <a href="/contact" className="hdr-curtain-nav-link" onClick={toggleMenu}>
            {i18n.t('contactHeader')}
          </a>
        </nav>
      </div>
    </header>
  );
}

export default Header;
